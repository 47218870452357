.primary {
  @apply bg-buttons-primary text-buttons-secondary p-2 rounded hover:bg-buttons-secondary hover:text-buttons-primary transition-all;
}

.secondary {
  @apply bg-transparent text-buttons-primary p-2 rounded border border-buttons-primary hover:text-buttons-secondary ;
}

.link {
  @apply bg-transparent text-buttons-link p-2 hover:text-buttons-secondary;
}

button {
  @apply gap-1 flex items-center justify-center;
}

button:disabled {
  @apply cursor-not-allowed opacity-30;
}

.lean {
  @apply p-0;
}

.primary.danger {
  @apply bg-red-800 text-red-200;
}

.secondary.danger {
  @apply text-red-800 border-red-800 hover:text-red-400;
}