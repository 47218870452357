@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-inline-start: 0;
  margin-inline-end: 0;
}
h1 {
  @apply text-h1;
  /* margin-block-end: 21.44px;
  margin-block-start: 21.44px; */
  
}
h2 {
  @apply text-h2;
  /* margin-block-start: 19.92px;
  margin-block-end: 19.92px; */
}
h3 {
  @apply text-h3;
}
h4 {
  @apply text-h4;
}
h5 {
  @apply text-h5;
}
h6 {
  @apply text-h6;
}

p {
  font-weight: 400;
  margin: 0.5rem 0;
}

ul {
  @apply block list-disc;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 2.5rem;
}
ol {
  @apply block list-decimal;
  margin-block-start: 1rem;
  margin-block-end: 1rem;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 2.5rem;
}
ul ul, ul ol, ol ul, ol ol {
  @apply block list-disc;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 2.5rem;
}
li {
  display: list-item;
  text-align: -webkit-match-parent;
}
